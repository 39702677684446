export const useSaleStore = defineStore("sale", () => {
  const activeSale: Ref<OneOzSale | null> = ref(null)

  const setSale = (sale: OneOzSale) => {
    activeSale.value = sale
  }

  return {
    activeSale,
    setSale,
  }
})
